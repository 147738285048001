'use strict';

angular.module('enervexSalesappApp').controller('ApplicationCtrl', function($scope, Application, Property, $stateParams, Flash, $state, _, Util, ProductSubtype, Fitting, VentMaterial, ApplicationSubtype, $q) {
	$scope.errors = {};
	$scope.configs = Property.configs()
	$scope.itemCodeDisabled = true
	$scope.codeWarning = Util.setCodeWarning()
	$scope.ctx = {
		formChanged: false,
		showAllAvailable: false
	}
	$scope.disableItemCode = function(){
		if ($scope.itemCodeDisabled === true) {
			$scope.itemCodeDisabled = false
		} else {
			$scope.itemCodeDisabled = true
		}
	}
	$scope.cpOutlets = [{
		name:'A',
		_id:'A'
	},{
		name:'B',
		_id:'B'
	},{
		name:'C',
		_id:'C'
	},{
		name:'D',
		_id:'D'
	}];
	$scope.triangleLengths = [
		24,
		36,
		48
	]
	$scope.lineOffsets = [ 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60 ]
	$scope.triangleFonts = [
		"7px HelveticaNeue",
		"8px HelveticaNeue",
		"9px HelveticaNeue",
		"10px HelveticaNeue",
		"12px HelveticaNeue",
		"14px HelveticaNeue",
		"16px HelveticaNeue",
		"18px HelveticaNeue",
		"20px HelveticaNeue",
		"22px HelveticaNeue",
		"24px HelveticaNeue",
	]

	$scope.pureVerticalStrategies = [{
		_id:"always",
		name: "Always"
	}, {
		_id:"never",
		name: "Never"
	}, {
		_id:"exceptCategories",
		name: "Always except following Categories"
	}]
	$scope.applianceCategories = [
		{
			name: "II",
			_id: "II"
		},{
			name: "IV",
			_id: "IV"
		},{
			name: "III",
			_id: "III"
		},{
			name: "I - Direct Connect",
			_id: "I - Direct Connect"
		},{
			name: "I - Draft Hood",
			_id: "I - Draft Hood"
		},{
			name: "I - Fan Assisted",
			_id: "I - Draft Hood"
		},{
			name: "Oil",
			_id: "Oil"
		}
	]

	$scope.sectionPositions = [{
		name: "Any",
		_id: "any"
	}, {
		name: "First (Starting Point)",
		_id: "first"
	}]

	$scope.maxCounts = [{
		name: "One",
		_id: 1
	}, {
		name: "Unlimited",
		_id: -1
	}]

	if ($stateParams.id == "new") {
		$scope.application = {}
		$scope.itemCodeDisabled = false
		fetchDeps()
	} else {
		Application.get({
			id: $stateParams.id
		}).$promise.then(function(application) {
			$scope.application = application;
			fetchDeps()
		})
	}
	$scope.is3Way = function(fittingId) {
		var fitting = _.find($scope.fittings, function(o){
			return fittingId == o._id
		})
		return fitting && fitting.secno > 2
	}
	$scope.getFittingImage = function(fittingId) {
		var fitting = _.find($scope.fittings, function(o){
			return fittingId == o._id
		})
		return fitting && fitting.asset && fitting.asset.asset.url
	}
	$scope.addCriteria = function() {
		var applianceCategories = _.map($scope.applianceCategories, function(_applianceCategory){
			var applianceCategory = _.clone(_applianceCategory)
			return applianceCategory
		})


		$scope.application.stack.criterias.push({
			type: 'totalDistance',
			direction: 'any',
			outterRingCondition: 'any',
			outterRingDim: 0,
			productSubtype: null,
			distance: 120,
			isCummulative: false,
			initialDistance: 0,
			countType: 'floor',
			availableProductSubtypes: _.map($scope.productSubtypes, function(o){
				var result = _.clone(o)
				result.selected = false
				return result
			}),
			cummulativeSubtypes: _.map($scope.productSubtypes, function(o){
				var result = _.clone(o)
				result.selected = false
				return result
			}),
			applicationSubtypes: _.map($scope.applicationSubtypes, function(o){
				var result = _.clone(o)
				result.selected = true
				return result
			}),
			override: false,
			restrictApplianceCategories: false,
			applianceCategories:applianceCategories,
			maxCount:-1,
			ignoreSawtoothLength:false,
			sectionPosition: 'any'
		})
	}
	$scope.removeCriteria = function(criteria) {
		$scope.application.stack.criterias = _.filter($scope.application.stack.criterias, function(o){
			var result = o == criteria
			return !result
		})
	}
	function fetchDeps() {
		if (!$scope.application.stack) {
			$scope.application.stack = {}
		}
		if (!$scope.application.properties.twoWayFittingConfig) {
			$scope.application.properties.twoWayFittingConfig = {}
		}
		if (!$scope.application.properties.twoWayFittingConfigPureVertical) {
			$scope.application.properties.twoWayFittingConfigPureVertical = {}
		}
		if (!$scope.application.stack.startingPointFittingConfig) {
			$scope.application.stack.startingPointFittingConfig = {}
		}
		if (!$scope.application.stack.startingPointFittingConfig.accessories) {
			$scope.application.stack.startingPointFittingConfig.accessories = []
		}
		if (!$scope.application.stack.criterias) {
			$scope.application.stack.criterias = []
		}

		$scope.types = [{
			name: 'Every Appliance Section',
			value: 'applianceSection'
		},{
			name: 'Change of Direction',
			value: 'changeOfDirection'
		},{
			name: 'Over total distance',
			value: 'totalDistance'
		},{
			name: 'Change of Material',
			value: 'changeOfMaterial'
		},{
			name: 'Cummulative Vertical No Turns',
			value: 'noturnsCummulative',
			isCummulative: true
		},{
			name: 'Cummulative Over total distance',
			value: 'totalDistanceCummulative',
			isCummulative: true
		}];
		$scope.countTypes = [{
			name: 'Floor',
			value: 'floor'
		},{
			name: 'Ceiling',
			value: 'ceiling'
		}];
		$scope.exhaustFanStrategys = [
			"allowNoneAlways",
			"standard"
		]
		$scope.supplyFanStrategys = [
			"allowNoneAlways",
			"standard"
		]
		$scope.outterRingConditions = ['>=', '<', 'any']
		$scope.directions = [{
			name:'horizontal', 
			value:'horizontal',
		}, {
			name:'vertical',
			value:'vertical'
		}, {
			name:'net',
			value:'any'
		}]

		$q.all([
			ApplicationSubtype.query().$promise,
			ProductSubtype.query().$promise,
			Fitting.query().$promise,
			VentMaterial.query().$promise
		]).then(function(result){
			var applicationSubtypes = result[0]
			$scope.allApplicatoinSubtypes = result[0]
			$scope.productSubtypes = Util.sortUncased(result[1], "name")
			$scope.accessories = Util.getSubtypes("Accessory", $scope.productSubtypes)
			$scope.productSubtypesCovers = _.filter($scope.productSubtypes, function(productSubtype){
				return productSubtype.inletCover
			})
			$scope.productSubtypesStartingPoints = Util.getSubtypes("StartingPoint", $scope.productSubtypes)
			$scope.fittings = result[2]
			$scope.fittingsFirstturn = _.filter($scope.fittings, function(fitting){
				return fitting.secno > 1 && fitting.active
			})
			$scope.allVentmaterials = result[3]
			loadApplication()
		})
	}
	function loadApplication() {
		loadVentMaterials()
		loadAccessories()
		loadCriteria()
		loadApplianceCategories()
		loadApplicationSubtypes()
	}
	function loadApplicationSubtypes() {
		$scope.applicationSubtypes = _.filter($scope.allApplicatoinSubtypes, function(o){
			return o.application._id == $scope.application._id
		})
	}
	function loadVentMaterials() {
		$scope.ventMaterials = _.map($scope.allVentmaterials, function(ventMaterial) {
			var existing = _.find($scope.application.ventMaterials, function(vmId){
				return vmId == ventMaterial._id
			})
			if (existing) {
				ventMaterial.selected = true
			}
			return ventMaterial
		})
	}
	function loadAccessories() {
		$scope.application.stack.startingPointFittingConfig.accessories = _.map($scope.accessories, function(_productSubtype){
			var productSubtype = _.clone(_productSubtype)
			var existing = _.find($scope.application.stack.startingPointFittingConfig.accessories, function(p){
				return p == productSubtype._id
			})
			productSubtype.selected = (existing) ? true : false
			return productSubtype
		})
	}
	function getDehydratedAttribute(attr) {
		if (!attr || _.isString(attr)) {
			return attr
		} else {
			return attr._id
		}
	}
	function loadApplianceCategories() {
		$scope.application.properties.applianceCategories = _.map($scope.applianceCategories, function(_applianceCategory){
			var applianceCategory = _.clone(_applianceCategory)
			var existing = _.find($scope.application.properties.applianceCategories, function(o){
				return _.isString(o) ? o == applianceCategory._id :  o._id == applianceCategory._id
			})
			if (existing) {
				if (_.isString(existing)){
					applianceCategory.selected = true
					return applianceCategory
				} else {
					return existing
				}
			} else {
				return applianceCategory
			}
		})
	}
	function loadCriteria() {
		_.each($scope.application.stack.criterias, function(criteria){
			if ($scope.productSubtypes && $scope.productSubtypes.length > 0) {
				criteria.availableProductSubtypes = _.map($scope.productSubtypes,function(_subtype){
					var subtype = _.clone(_subtype)
					var existing = _.find(criteria.availableProductSubtypes, function(o){
						return getDehydratedAttribute(o) == subtype._id 
					})
					if (existing) {
						subtype.selected = true
					}
					return subtype
				})
				criteria.cummulativeSubtypes = _.map($scope.productSubtypes,function(_subtype){
					var subtype = _.clone(_subtype)
					var existing = _.find(criteria.cummulativeSubtypes, function(o){
						return getDehydratedAttribute(o) == subtype._id
					})
					if (existing) {
						subtype.selected = true
					}
					return subtype
				})
			}
			if ($scope.applicationSubtypes && $scope.applicationSubtypes.length > 0) {
				criteria.applicationSubtypes = _.map($scope.applicationSubtypes,function(_subtype){
					var subtype = _.clone(_subtype)
					var existing = _.find(criteria.applicationSubtypes, function(o){
						return getDehydratedAttribute(o) == subtype._id
					})
					if (existing) {
						subtype.selected = true
					}
					return subtype
				})
			}
			criteria.applianceCategories = _.map($scope.applianceCategories, function(_applianceCategory){
				var applianceCategory = _.clone(_applianceCategory)
				var existing = _.find(criteria.applianceCategories, function(o){
					return _.isString(o) ? o == applianceCategory._id :  o._id == applianceCategory._id
				})
				if (existing) {
					if (_.isString(existing)){
						applianceCategory.selected = true
						return applianceCategory
					} else {
						return existing
					}
				} else {
					return applianceCategory
				}
			})
			criteria.selectedVentMaterials = _.map($scope.ventMaterials, function(_ventMaterial){
				var ventMaterial = _.clone(_ventMaterial)
				var existing = _.find(criteria.ventMaterials, function(o){
					return _.isString(o) ? o == ventMaterial._id :  o._id == ventMaterial._id
				})
				if (existing) {
					if (_.isString(existing)){
						ventMaterial.selected = true
						return ventMaterial
					} else {
						return existing
					}
				} else {
					return ventMaterial
				}
			})
			return true
		})
	}
	$scope.selectAllVentMaterials = function(criteria, value) {
		_.each(criteria.selectedVentMaterials, function (ventMaterial){
			ventMaterial.selected = value
		})
	}
	$scope.productSubtypeName = function(productSubtypeId) {
		var productSubtype = _.find($scope.productSubtypes, function(p){
			return p._id == productSubtypeId
		})
		return (productSubtype) ? productSubtype.name : null
	}
	$scope.computeAses = [
		"BWH",
		"DRY",
		"COM",
		"none"
	]

	$scope.save = function(form) {
		$scope.submitted = true;
		if (form.$valid) {
			Flash.clear();
			var payload = _.clone($scope.application);
			payload = _.omit(payload, "__v");
			payload.ventMaterials = _.compact(_.map($scope.ventMaterials, function(o){
				return o.selected ? o._id : null
			}))
			payload.stack.startingPointFittingConfig.accessories = _.compact(_.map(payload.stack.startingPointFittingConfig.accessories, function(o){
				return o.selected ? o._id : null
			}))
			payload.stack.criterias = _.map(payload.stack.criterias, function(criteria){
				var o = _.clone(criteria)
				o.availableProductSubtypes = _.compact(_.map(o.availableProductSubtypes, function(o){
					return o.selected ? o._id : null
				}))
				o.cummulativeSubtypes = _.compact(_.map(o.cummulativeSubtypes, function(o){
					return o.selected ? o._id : null
				}))
				o.applicationSubtypes = _.compact(_.map(o.applicationSubtypes, function(o){
					return o.selected ? o._id : null
				}))
				o.applianceCategories = _.compact(_.map(o.applianceCategories, function(o){
					return o.selected ? o._id : null
				}))
				o.ventMaterials = _.compact(_.map(o.selectedVentMaterials, function(o){
					return o.selected ? o._id : null
				}))
				delete o.selectedVentMaterials
				var type = _.find($scope.types, function(o){
					return o.value == criteria.type
				})
				o.isCummulative = type.isCummulative
				return o
			})
			payload.properties = _.clone(payload.properties)
			payload.properties.applianceCategories = _.compact(_.map(payload.properties.applianceCategories, function(o){
				return o.selected ? o._id : null
			}))
			if (!$scope.application._id) {
				Application.save(payload).$promise.then(function(result) {
					$scope.success = true;
					$scope.application = result;
					loadApplication()
					Flash.create('success', '<strong>Success!</strong> Successfully created fan phase.', 0, {}, true);
					$state.go("application", {
						id: result._id
					}, {
						reload: true,
						inherit: false
					})
				}).catch(function(err) {
					$scope.errors = {};
					$scope.errors.other = 'Problem saving';
					var message = (err.data && err.data.message) || "There was an error saving";
					Flash.create('danger', '<strong>Error!</strong> ' + message, 0, {}, true);
				})
			} else {
				Application.update({
					id: $scope.application._id
				}, payload).$promise.then(function(application) {
					$scope.success = true;
					$scope.application = application;
					loadApplication()
					Flash.create('success', '<strong>Success!</strong> Successfully updated fan phase.', 0, {}, true);
				}).catch(function(err) {
					$scope.errors = {};
					$scope.errors.other = 'Problem saving';
					var message = (err.data && err.data.message) || "There was an error saving";
					Flash.create('danger', '<strong>Error!</strong> ' + message, 0, {}, true);
				})
			}
		} else {
			$scope.errors = {};
			$scope.errors.other = 'Problem saving';
			var message = "Please fix validation errors";
			Flash.create('danger', '<strong>Error!</strong> ' + message, 0, {}, true);
		}
	};
});
